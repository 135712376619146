/* eslint-disable */
import { Alert, Button, Col, Drawer, Popconfirm, Row, Select, Tabs, Tag } from "antd"
import { useEffect, useState } from "react";

import { FullScreen, useFullScreenHandle } from "react-full-screen";

import {
    AiOutlineFullscreen,
    AiOutlineFullscreenExit,
    AiOutlineSetting
} from "react-icons/ai";
import { GetLatestID, GetOrdersToday, UpdateOrder, socket } from "../services/api";
import { OrderFrame } from "../components/OrderFrame";

import useSound from 'use-sound'
import { BiLogOut } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { isMobile, isTablet } from "react-device-detect";
export const Kitchen = (props) => {
    const {
        setuser, user
    } = props

    useEffect(() => {
        try {
            const host = window.location.host; // gets the full domain of the app
            const hostIsLocal = host.includes("localhost")
            const hostarr = host.split(".")
            const protocol = window.location.protocol
            var id = user.store.store_id
            id = id.replaceAll('.','').replaceAll('/','').replaceAll(' ', '_')
            if (hostIsLocal && hostarr.length === 0) {
                window.location.href = `${protocol}//${id}.${host}`
            }
            if (!hostIsLocal && hostarr.length <= 2) {
                window.location.href = `${protocol}//${id}.${host}`
            }
            console.log(`${protocol}//${id}.${host}`)
        } catch (error) {
            console.log(error.message)
        }
    }, []);

    const [playNew] = useSound('/sounds/warning.mp3', { interrupt: true, });
    const [playTap] = useSound('/sounds/tap.mp3', { interrupt: true, });

    const [open, setopen] = useState(false)
    const [orders, setorders] = useState(null)
    const splitOrdersDef = {
        allActive: null,
        countallActive: 0,
        new: null,
        countnew: 0,
        preparing: null,
        countpreparing: 0,
        done: null,
        countdone: 0
    }
    const [splitOrders, setsplitOrders] = useState(splitOrdersDef)
    const store_id = user.store._id
    const store_name = user.store.business_name
    const [latestID, setlatestID] = useState(localStorage.getItem('latestID'))
    const SetOrders = async () => {
        await GetOrdersToday(store_id)
            .then(res => {
                const data = res.data
                //console.log(data)
                const result = data.data
                const notEmpty = result.length !== 0
                if (notEmpty) {
                    setorders(result)
                    const allActive = result.filter((obj) => { return obj.kitchenStatus !== "done" })
                    const nw = result.filter((obj) => { return obj.kitchenStatus === "new" })
                    const preparing = result.filter((obj) => { return obj.kitchenStatus === "preparing" })
                    const done = result.filter((obj) => { return obj.kitchenStatus === "done" })
                    setsplitOrders({
                        allActive: allActive,
                        countallActive: allActive.length,
                        new: nw,
                        countnew: nw.length,
                        preparing: preparing,
                        countpreparing: preparing.length,
                        done: done,
                        countdone: done.length
                    })
                }

            }).catch(err => {
                console.log(err.message)
                setorders(null)
            })
    }

    const getLatestID = latest_id => {
        console.log(latest_id)
        if (latest_id !== null) {
            if (latest_id !== latestID) {
                playNew()
                localStorage.setItem('latestID', latest_id)
                socket.emit("set_orders", user.store._id);
                socket.on("get_orders", getLatestOrders);
            }
            setlatestID(latest_id)
        }
    }

    const getLatestOrders = orders => {
        //console.log(orders)
        const notEmpty = orders.length !== 0 || orders !== null
        if (notEmpty) {
            setorders(orders)
            const allActive = orders.filter((obj) => { return obj.kitchenStatus !== "done" })
            const nw = orders.filter((obj) => { return obj.kitchenStatus === "new" })
            const preparing = orders.filter((obj) => { return obj.kitchenStatus === "preparing" })
            const done = orders.filter((obj) => { return obj.kitchenStatus === "done" })
            setsplitOrders({
                allActive: allActive,
                countallActive: allActive.length,
                new: nw,
                countnew: nw.length,
                preparing: preparing,
                countpreparing: preparing.length,
                done: done,
                countdone: done.length
            })
        }
    }


    useEffect(() => {
        if (user !== null) {
            socket.emit("set_latest_order_id", user.store._id);//this will be called from kiosk
            socket.on("get_latest_order_id", getLatestID);

            SetOrders()
        }
    }, [])

    const onChange = (key) => {
        if (splitOrders[key] !== 0) {
            playTap()
        }
    };

    const items = [
        {
            key: 'countallActive',
            label: <span>
                All Active <Tag>{splitOrders.countallActive}</Tag>
            </span>,
            children: <OrderFrame title={"All Active"} orders={splitOrders.allActive} SetOrders={SetOrders} />
        },
        {
            key: 'countnew',
            label: <span>
                New <Tag>{splitOrders.countnew}</Tag>
            </span>,
            children: <OrderFrame title={"New"} orders={splitOrders.new} SetOrders={SetOrders} />
        },
        {
            key: 'countpreparing',
            label: <span>
                Preparing <Tag>{splitOrders.countpreparing}</Tag>
            </span>,
            children: <OrderFrame title={"Preparing"} orders={splitOrders.preparing} SetOrders={SetOrders} />
        },
        {
            key: 'countdone',
            label: <span>
                Done <Tag>{splitOrders.countdone}</Tag>
            </span>,
            children: <OrderFrame title={"Done"} orders={splitOrders.done} SetOrders={SetOrders} />
        },
        {
            key: '5',
            label: <span>
                Archive <Tag>{orders !== null ? orders.length : 0}</Tag>
            </span>,
            children: <OrderFrame title={"All Active"} orders={orders} SetOrders={SetOrders} />
        },
    ];

    const [isFullscreen, setisFullscreen] = useState(false)
    const handle = useFullScreenHandle();
    const handleFullscreen = () => {
        if (isFullscreen) {
            handle.exit()
        } else {
            handle.enter()
        }
        setisFullscreen(!isFullscreen)
    }

    const iconStyle = { fontSize: 25, cursor: 'pointer' }
    const FullScreenSettings = () => {
        return <div className="overlay-topRight"
            onClick={() => {
                handleFullscreen()
            }}
        >
            {isFullscreen ? <AiOutlineFullscreenExit style={iconStyle} /> : <AiOutlineFullscreen style={iconStyle} />}
        </div>
    }
    const navigate = useNavigate()
    const logout = () => {
        navigate('/')
        setuser(null)
        localStorage.setItem("testing", "false")
        localStorage.removeItem("JWT")
    }
    return (
        <div className="wrapper" style={{ margin: 0, padding: 0 }} >
            <FullScreen handle={handle} >
                <Row style={{ margin: 0, padding: 0 }} >
                    <Col xs={24} style={{ margin: 0, padding: 0, position: "relative" }}>
                        {isTablet && <div className="overlay-topLeft kitchen-header">
                            {store_name} | Kitchen Order
                        </div>}
                        <Tabs centered tabPosition={"top"} defaultActiveKey="1" items={items} onChange={onChange} />;
                        {FullScreenSettings()}
                        <div className="setting-drwer" onClick={() => {
                            setopen(true)
                        }}>
                            <AiOutlineSetting />
                        </div>
                        <div className="overlay-bottomLeft">
                            {localStorage.getItem("testing") === "true" && <Tag color={"red"}>TESTING MODE</Tag>}
                        </div>
                        <Drawer
                            open={open}
                            title={"Account Settings"}
                            onClose={() => { setopen(false) }}
                        >
                            <Row>
                                {
                                    user !== null && (
                                        <Col xs={24}>
                                            <b>{user.store.business_name}</b><br />
                                            <Popconfirm
                                                title={"Are you sure you want to logout?"}
                                                onConfirm={() => {
                                                    logout()
                                                }}
                                                okButtonProps={{ style: { backgroundColor: "transparent", border: "", color: "black" } }}
                                                showCancel={false}
                                                cancelButtonProps={{ style: { backgroundColor: "transparent", border: "", color: "black" } }}
                                                okText={"Yes"}
                                                cancelText={"No"}
                                                placement={"topLeft"}
                                            >
                                                <Button type="link" danger style={{ float: "right" }} >
                                                    Logout?
                                                </Button>
                                            </Popconfirm>


                                        </Col>
                                    )
                                }
                                <Col xs={24} className="mb-2">
                                    <Alert closable type="info" message={<center><small><b>New Order Sound📢</b></small></center>} description={<center><small>Use Microsoft Edge or any browser except Chrome</small></center>} />
                                </Col>
                                <Col xs={24}>
                                    <b>On Testing?</b>
                                    <Select
                                        style={{ width: "100%" }}
                                        value={localStorage.getItem("testing")}
                                        onChange={e => {
                                            localStorage.setItem("testing", e)
                                            window.location.reload()
                                        }}
                                        options={[
                                            {
                                                label: "Yes",
                                                value: "true"
                                            },
                                            {
                                                label: "No",
                                                value: "false"
                                            },
                                        ]}
                                    />
                                </Col>
                            </Row>
                        </Drawer>
                    </Col>
                </Row>
            </FullScreen>

        </div>

    )
}