/* eslint-disable */
import { Row, Col, Card, Image, Form, Input, Button, notification } from "antd";
import { isMobile } from "react-device-detect"
import login from "../assets/images/loginImg.png"
import logo from "../assets/images/bm-logo.png"
import { Login } from "../services/api";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

export const SignIn = (props) => {
    const { setuser } = props
    const navigate = useNavigate();
    const [loading, setloading] = useState(false)

    const onFinishFailed = err => {
        console.log(err)
    }

    const signInUser = async (values) => {
        setloading(true)
        await Login(values)
            .then(res => {
                const data = res.data
                console.log(data)
                if (data.msg === undefined) {
                    setuser(data.userInfo)
                    localStorage.setItem("JWT", data.tokenized)
                    navigate("/kitchen")
                } else {
                    notification.warning({
                        message: data.msg
                    })
                }
                setloading(false)
            }).catch(err => {
                setloading(false)
                console.log(err.message)
                setuser(null)
                localStorage.removeItem("JWT")
            })
    }
    const [form] = Form.useForm()
    return (
        <Row>
            <Col xs={24} style={{ position: "fixed", top: isMobile? "20%" : "30%" }}>
                <center>
                    <Col xs={isMobile ? 20 : 12}>
                        <Card bordered className="card-modern">
                            <Row>
                                <Col xs={24}>
                                    <img className="fl" src={logo} alt={""} width={"20%"} />
                                </Col>
                                <Col xs={24} lg={12} style={{ padding: 20 }}>

                                    <Form
                                        form={form}
                                        layout="vertical"
                                        initialValues={{
                                            email: '',
                                            password: '',
                                        }}
                                        onFinish={signInUser}
                                        onFinishFailed={onFinishFailed}
                                    >
                                        <Form.Item
                                            name={"email"}
                                            label={"Email"}
                                            rules={[{ required: true, message: 'Please input your Email!' }]}
                                        >
                                            <Input placeholder="Email" />
                                        </Form.Item>
                                        <Form.Item
                                            name={"password"}
                                            label={"Password"}
                                            rules={[{ required: true, message: 'Please input your Password!' }]}
                                        >
                                            <Input.Password size="small" placeholder="Password" />
                                        </Form.Item>
                                    </Form>
                                    <Button
                                        loading={loading}
                                        type={"primary"}
                                        onClick={() => {
                                            form.submit()
                                        }}
                                    >LOGIN</Button>
                                </Col>
                                <Col xs={24} lg={12} hidden={isMobile}>
                                    <Image
                                        preview={false}
                                        src={login}
                                        width={"100%"}
                                        height={"100%"}
                                    />
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </center>
            </Col>
        </Row>
    )
}