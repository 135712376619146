/* eslint-disable */
import { Card, Row, Col, Space, Tag, Badge, Table, List, Button, notification } from "antd";
import { capitalize, checkArray, elapseTime, formatPrice, parseFloat2 } from "../utils/helpers";

import {
    CheckCircleOutlined,
    ClockCircleOutlined,
    CloseCircleOutlined,
    ExclamationCircleOutlined,
    MinusCircleOutlined,
    SyncOutlined,
} from '@ant-design/icons';
import moment from "moment";
import { DisplayVariationAddons } from "./ProductVariationAddons";
import { UpdateOrder } from "../services/api";
import { useState } from "react";
import { useEffect } from "react";

export const OrderCard = (props) => {
    const {
        order,
        SetOrders,
        k,
        playBlep,
        playTing
    } = props

    var {
        elapsedTime,
        kitchenStatus,
        createdAt,
        order_type,
        hst, discount,
        grandtotal,
        crt, mrt,
        product_array
    } = order
    const [loading, setloading] = useState(false)
    const status = kitchenStatus
    const [elapsed, setelapsed] = useState(elapseTime(moment(createdAt)))
    useEffect(() => {
        const interval = setInterval(() => {
            setelapsed(elapseTime(moment(createdAt)))
        }, 1000);
        return () => clearInterval(interval);
    }, []);
    const isDine = order_type === "dinein"
    const textElapsed = status === "done" ? elapsedTime : elapsed
    const elapsedColorIndicator = () => {
        try {
            const time = elapsed[0]
            const elLen = elapsed.split(" ").length
            if (elLen === 1) {
                //seconds
                return time.includes("h") ? "red" : "green"
            }
            if (elLen > 1 && elLen <= 2) {
                //minutes
                return time.includes("h") ? "red" : ""
            }
            if (elLen > 2 && elLen <= 4) {
                //hours
                return "red"
            }
        } catch (error) {
            return ""
        }
    }

    const headerIcon = () => {
        switch (status) {
            case "done":
                return <CheckCircleOutlined />
            case "pending":
                return <SyncOutlined spin />
            case "preparing":
                return <SyncOutlined spin />
            case "new":
                return <ExclamationCircleOutlined />
            default:
                return <></>
        }
    }

    const TakeOrder = async (status) => {
        setloading(true)
        if(status==="done"){
            playTing()
        } else {
            playBlep()
        }
        await UpdateOrder(order._id, elapsed, status)
            .then(res => {
                SetOrders()
                setloading(false)
            }).catch(err => {
                console.log(err.message)
                setloading(false)
            })
    }
    const ActionUI = () => {
        var label = status === "new" ? "PREPARE ORDER" : status === "preparing" || status === "pending" ? "DONE" : status === undefined ? "PREPARE ORDER" : ""
        const hide = status === "done"
        return <Button
            loading={loading}
            disabled={hide}
            style={hide ? { backgroundColor: "white", border: "white" } : {}}
            type={"primary"}
            onClick={async () => {
                const status = label === "PREPARE ORDER" ? "preparing" : "done"
                await TakeOrder(status)
            }}
        >
            {label}
        </Button>
    }
    return (
        <Badge.Ribbon text={<small>{textElapsed}</small>} color={elapsedColorIndicator()}>
            <Card
                className={`order-card order-${status}`}
                headStyle={{ padding: 5, margin: 0, height: 20 }}
                bodyStyle={{ backgroundColor: "white", height: "auto" }} //height: window.innerHeight * .321
                actions={[
                    ActionUI()
                ]}
                title={<div
                    className="order-card-header"
                >
                    <Tag style={{ fontWeight: "bold" }} color={"black"}>#{k}</Tag>
                    <Tag style={{ fontWeight: "bold", border: "0px", fontSize: 15 }} icon={headerIcon()}>
                        {capitalize(status)}
                    </Tag>
                    <br />
                    <center>
                        <Space
                            align="center"
                            direction="horizontal"
                        >
                            Order# <u>{order.order_no}</u> |
                            <Tag color={isDine ? "#55acee" : "#87d068"}>{isDine ? "Dine In" : "Take Out"}</Tag> |
                            {moment(createdAt).format('MMM DD, YYYY hh:mm A')}

                        </Space>
                    </center>
                </div>}
            >
                <List
                    size="small"
                    className="content"
                    header={null}
                    footer={null}
                    bordered={false}
                    style={{ margin: 0, padding: 0 }}
                    dataSource={product_array}
                    renderItem={(val) => (
                        <List.Item>
                            <Col xs={24}>
                                <Space>
                                    <span style={{ fontWeight: "300" }}><b>{val.quantity}</b> <i>{`${val.quantity > 1 ? "pcs" : "pc"}`}</i></span>
                                    <span style={{ fontWeight: "bold" }}> {val.product_name} </span>
                                </Space>
                                <small style={{ fontWeight: "bold", float: "right" }} hidden>{formatPrice(val.product_price)} </small>    <br />
                                <Col style={{}} xs={24}>
                                    {
                                        checkArray(val.variants) &&
                                        <DisplayVariationAddons val={val.variants} />
                                    }
                                    {
                                        checkArray(val.addons) &&
                                        <DisplayVariationAddons val={val.additionals} />
                                    }
                                    {
                                        checkArray(val.variants) || checkArray(val.addons) ?
                                            <small hidden>
                                                <hr />
                                                Variant/Addon Total:
                                                <b style={{ float: "right", fontSize: 14 }}>
                                                    {formatPrice(parseFloat2(val.addonstotal + val.variantstotal))}
                                                </b>
                                            </small>
                                            : <></>
                                    }
                                </Col>
                            </Col>
                        </List.Item>
                    )}
                />

            </Card>
        </Badge.Ribbon>
    )
}