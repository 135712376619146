import humanizeDuration from 'humanize-duration'
import moment from 'moment'

export let capitalize = (string) => {
    try {
        return string.charAt(0).toUpperCase() + string.slice(1)
    } catch (error) {
        console.log(error.message)
        return ""
    }
}

export let elapseTime = (createdAt) => {
    try {
        let past = new Date(createdAt);
        let now = new Date();
        let elapsed = (now - past); //elapsed by seconds

        const shortEnglish = humanizeDuration.humanizer({
            language: "shortEn",
            round: true,
            delimiter: " ",
            spacer: "",
            languages: {
                shortEn: {
                    y: () => "y",
                    mo: () => "mo",
                    w: () => "w",
                    d: () => "d",
                    h: () => "h",
                    m: () => "m",
                    s: () => "s",
                    ms: () => "ms",
                },
            },
        });
        return shortEnglish(elapsed)
    } catch (error) {
        console.log(error.message)
        return ""
    }
}


export const getImg = (image) => {
    return `https://bm-img.nyc3.digitaloceanspaces.com/${image}`
}

export const parseFloat2 = (number) => {
    return parseFloat(parseFloat(number).toFixed(2))
}

export const checkArray = (arr) => {
    try {
        if (
            typeof arr !== "undefined" &&
            arr !== null &&
            arr.length !== null &&
            arr.length > 0
        ) {
            if (arr[0] !== "") {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    } catch (error) {
        console.log("checkArray")
        console.log(error.message)
        return false
    }
}

export const formatPrice = (number) => {
    const fnumber = isNaN(number) ? 0 : parseFloat(number);
    return `$${fnumber}`
}

export const calculateSumInArrayObject = (array, property) => {
    try {
        if (checkArray(array)) {
            const total = array.reduce((accumulator, object) => {
                return accumulator + object[property];
            }, 0);

            return total;
        } else {
            return 0
        }
    } catch (error) {
        console.log(error.message)
        return 0
    }
}

export const generateRandomKey = () => {
    try {
        const dateNow = moment().format("MMDDYY")
        const randInt = Math.floor(Math.random() * (1000000 - 9999999) + 9999999)
        const current = `${dateNow}-${randInt}`
        return current
    } catch (err) {
        console.log("generateOrderID")
        console.log(err.message)
        return ""
    }
}