/* eslint-disable */
import { Row,Col } from "antd"
import { useState } from "react"
import { formatPrice, generateRandomKey, parseFloat2 } from "../utils/helpers"

export const DisplayVariationAddons = (props) => {
    try {
        const {
            val
        } = props
        const filteredVal = val.filter((obj) => {
            const selected = obj.selected
            return selected.length !== 0
        })
        const notEmpty = filteredVal.length !== 0
        return <>
            {
                notEmpty && filteredVal.map((val, k) => {
                    const option = val.selection
                    if(!Array.isArray(val.add)){
                        val.add = [val.add]
                    }
                    if(!Array.isArray(val.selected)){
                        val.selected = [val.selected]
                    }
                    var add = [...val.add]
                    var selected = [...val.selected]
                    var newSelected = selected.filter((e) => {
                        return e !== undefined
                    })
                    var newAdd = add.filter((e) => {
                        return e !== undefined
                    })
                    const selNotEmpty = newSelected.length !== 0
                    const selIsSingle = newSelected.length === 1
                    return <Row style={{ margin: 0, paddiing: 0 }} key={generateRandomKey()}>
                        <Col xs={10} style={{ margin: 0, padding: 0 }} key={generateRandomKey()}>
                            <small>
                                {option}:
                            </small>
                        </Col>
                        <Col xs={14} style={{ margin: 0, padding: 0, marginBottom: selIsSingle ? 0 : 5 }} key={generateRandomKey()}>
                            <Row style={{ margin: 0, padding: 0 }}>
                                {
                                    selNotEmpty && newSelected.map((sel, sk) => {
                                        const price = newAdd[sk]
                                        return <Col xs={24} style={{ textAlign: "right",//selIsSingle ? "center" : "right",
                                                 margin: 0, paddiing: 0 }} key={generateRandomKey()}>
                                            <small key={generateRandomKey()}>
                                                <b key={generateRandomKey()}>{sel} {
                                                        //price !== 0 && formatPrice(parseFloat2(price))
                                                    }
                                                </b>
                                                    <br />
                                            </small>
                                        </Col>
                                    })
                                }
                            </Row>
                        </Col>
                    </Row>
                })
            }
        </>
    } catch (error) {
        console.log("DisplayVariationAddons")
        console.log(error.message)
        return <>
        </>
    }
}