/* eslint-disable */
import { Navigate, Route, Routes } from 'react-router-dom';
import { Kitchen } from './pages/Kitchen';

import "./assets/styles/index.css"
import "./assets/styles/main.css"
import "./assets/styles/responsive.css"
import { useEffect, useState } from 'react';
import { SignIn } from './pages/SignIn';
import { VerifyToken } from './services/api';
const App = () => {

  useEffect(() => {
    document.title = 'Paykiosk | Kitchen App';
  }, []);

  const [user, setuser] = useState(null)

  const token = localStorage.getItem("JWT")
  console.log(token)
  const decryptToken = async () => {
    await VerifyToken(token)
      .then(res => {
        console.log(res.data)
        const data = res.data
        if (data.msg === "ok") {
          setuser(data.decoded.data)
        } else {
          setuser(null)
        }
      }).catch(err => {
        console.log(err.message)
        setuser(null)
      })
  }
  useEffect(() => {
    decryptToken()
    const testing = localStorage.getItem("testing")
    if(testing===null){
      localStorage.setItem("testing", "false")
    }
  }, [])
  return (
    <>
    <Routes>
      {
        user !== null ?
          <Route path="/kitchen" element={<Kitchen user={user} setuser={setuser} />} />
          :
          <Route path="/" element={<SignIn setuser={setuser} />} />
      }
     
    </Routes>
     {
      user!== null ? (
        <Navigate to="/kitchen" replace={true} />
      )
      : (
        <Navigate to="/" replace={true} />
      )
    }
    </>
  );
}
export default App