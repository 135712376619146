/* eslint-disable */

import { Row, Col, Table } from "antd"
import { OrderCard } from "./OrderCard"
import useSound from "use-sound"

export const OrderFrame = (props) => {
    const {
        title, orders,
        SetOrders
    } = props
    
    const [playBlep] = useSound('/sounds/blep.mp3', {  interrupt: true, });
    const [playTing] = useSound('/sounds/ting.mp3', {  interrupt: true, });
    return (
        <Col xs={24}  style={{ padding: 10, position: "relative", height: window.innerHeight * .9 }}>
            <Row gutter={[24, 10]} className="content">
                {
                    orders !== null && orders.map((val, k) => {
                        const nk = String(k + 1) 
                        return <Col sm={12} xs={24} lg={8} xl={6} key={k} style={{ margin: 0 }}>
                            <OrderCard order={val} k={nk} SetOrders={SetOrders} playBlep={playBlep} playTing={playTing}/>
                        </Col>
                    })
                }
                <Col xs={24}>
                {
                    orders!==null&&orders.length===0&&<Table dataSource={null} />
                }
                </Col>
            </Row>
        </Col>
    )

}
